var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container px-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('AppBox', {
    staticClass: "col-xs-12 col-md-6 col-lg-4",
    attrs: {
      "header": "Temperatura"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between mx-3 mb-1"
  }, [_c('div', [_vm._v(" Temperatura góra: ")]), _c('strong', [_vm._v(" " + _vm._s(_vm.piec.t8) + " ")])]), _c('div', {
    staticClass: "d-flex justify-content-between mx-3 mb-1"
  }, [_c('div', [_vm._v(" Temperatura środek: ")]), _c('strong', [_vm._v(" " + _vm._s(_vm.piec.t9) + " ")])]), _c('div', {
    staticClass: "d-flex justify-content-between mx-3"
  }, [_c('div', [_vm._v(" Nagrzewanie: ")]), _vm.piec.d_mode === 4 ? _c('strong', [_vm._v(" elektryczne ")]) : _c('strong', [_vm._v(" bufor ")])])]), _c('AppBox', {
    staticClass: "col-xs-12 col-md-6 col-lg-4",
    attrs: {
      "header": "Wykres temperatury"
    }
  }, [_c('stat-chart', {
    attrs: {
      "driver": "piec",
      "variable": "t9"
    }
  })], 1), _c('AppBox', {
    staticClass: "col-xs-12 col-md-6 col-lg-4",
    attrs: {
      "header": "Tryb pracy"
    }
  }, [_c('FunctionBtn', {
    attrs: {
      "align": "center",
      "driver": "piec",
      "func": "pompwoda",
      "autoload": true,
      "size": "md"
    }
  })], 1), _c('AppBox', {
    staticClass: "col-xs-12 col-md-6 col-lg-4",
    attrs: {
      "header": "Ustawienia wody"
    }
  }, [_c('div', {
    staticClass: "my-3"
  }, [_vm._v(" Temperatura podtrzymania ")]), _c('FunctionBtn', {
    attrs: {
      "driver": "piec",
      "func": "setmaxcwu",
      "autoload": true,
      "size": "sm",
      "align": "center"
    }
  }), _c('div', {
    staticClass: "my-3"
  }, [_vm._v(" Temperatura minimalna środka"), _c('br'), _c('small', [_vm._v("(w max godzinach / manualu)")])]), _c('FunctionBtn', {
    attrs: {
      "driver": "piec",
      "func": "setmincwuc",
      "autoload": true,
      "size": "sm",
      "align": "center"
    }
  })], 1), _c('AppBox', {
    staticClass: "col-xs-12 col-md-6 col-lg-4",
    attrs: {
      "header": "Ustawienia godzin"
    }
  }, [_c('time-settings', {
    attrs: {
      "driver": "piec",
      "func": "wodasetup"
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }