<template>
  <div class="container px-0">
    <div class="row">
      <AppBox header="Temperatura" class="col-xs-12 col-md-6 col-lg-4">
        <div class="d-flex justify-content-between mx-3 mb-1">
          <div>
            Temperatura góra:
          </div>
          <strong>
            {{ piec.t8 }}
          </strong>
        </div>
        <div class="d-flex justify-content-between mx-3 mb-1">
          <div>
            Temperatura środek:
          </div>
          <strong>
            {{ piec.t9 }}
          </strong>
        </div>

        <div class="d-flex justify-content-between mx-3">
          <div>
            Nagrzewanie:
          </div>
          <strong v-if="piec.d_mode === 4">
            elektryczne
          </strong>
          <strong v-else>
            bufor
          </strong>
        </div>
      </AppBox>

      <AppBox header="Wykres temperatury" class="col-xs-12 col-md-6 col-lg-4">
        <stat-chart driver="piec" variable="t9" />
      </AppBox>

      <AppBox header="Tryb pracy" class="col-xs-12 col-md-6 col-lg-4">
        <FunctionBtn
          align="center"
          driver="piec"
          func="pompwoda"
          :autoload="true"
          size="md"
        />
      </AppBox>

      <AppBox header="Ustawienia wody" class="col-xs-12 col-md-6 col-lg-4">
        <div class="my-3">
          Temperatura podtrzymania
        </div>
        <FunctionBtn
          driver="piec"
          func="setmaxcwu"
          :autoload="true"
          size="sm"
          align="center"
        />

        <div class="my-3">
          Temperatura minimalna środka<br />
          <small>(w max godzinach / manualu)</small>
        </div>
        <FunctionBtn
          driver="piec"
          func="setmincwuc"
          :autoload="true"
          size="sm"
          align="center"
        />
      </AppBox>

      <AppBox header="Ustawienia godzin" class="col-xs-12 col-md-6 col-lg-4">
        <time-settings driver="piec" func="wodasetup" />
      </AppBox>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import StatBtn from '@/components/helpers/stat-btn'
import StatChart from '@/components/helpers/stat-chart'

export default {
  name: 'ShowerView',

  components: {
    StatBtn,
    StatChart
  },

  computed: {
    ...mapState(['driversData']),

    piec() {
      if ('piec' in this.driversData) {
        return this.driversData.piec
      } else {
        return false
      }
    }
  }
}
</script>
